import React, { type FC, useCallback } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import type { DialogProps } from 'wix-ui-tpa/cssVars';
import { ButtonPriority, Dialog, TextTypography } from 'wix-ui-tpa/cssVars';
import { BiModalCloseTrigger } from '@services/BiLogger';
import { Button, type ButtonProps } from '@components/Button';
import { Text } from '@components/Text';
import { useScrollControl } from './ModalContext';
import { DIALOG_DESCRIPTION_ID, DIALOG_TITLE_ID } from './constants';
import { st, classes } from './AlertDialog.st.css';

type AlertModalProps = Omit<DialogProps, 'onClose'> & {
  title: string;
  isSubmitting?: boolean;
  cancelText: string;
  submitText: string;
  submitIcon?: ButtonProps['prefixIcon'];
  onSubmit: () => void;
  onClose?: (action: BiModalCloseTrigger) => void;
};

export const AlertDialog: FC<AlertModalProps> = (props) => {
  const {
    title,
    isOpen,
    isSubmitting,
    children,
    cancelText,
    submitText,
    submitIcon,
    onSubmit,
    onClose,
  } = props;
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  useScrollControl(isOpen);

  const createCloseHandler = useCallback(
    (action: BiModalCloseTrigger) => () => {
      onClose?.(action);
    },
    [onClose],
  );

  return (
    <Dialog
      notFullscreenOnMobile
      isOpen={isOpen}
      className={st(classes.root, { isMobile })}
      contentClassName={st(classes.content)}
      aria-labelledby={DIALOG_TITLE_ID}
      aria-describedby={DIALOG_DESCRIPTION_ID}
      closeButtonAriaLabel={t('app.modal.close-button.aria-label')}
      onClose={createCloseHandler(BiModalCloseTrigger.ClickXButton)}
      forceBWTheme
      // TODO: Need to uncomment after the wiring is made
      // wiredToSiteColors
    >
      <Text
        id={DIALOG_TITLE_ID}
        tagName="h2"
        typography={
          isMobile ? TextTypography.smallTitle : TextTypography.largeTitle
        }
        className={st(classes.title)}
      >
        {title}
      </Text>
      <Text id={DIALOG_DESCRIPTION_ID} tagName="p">
        {children}
      </Text>
      <div className={st(classes.actions)}>
        <Button
          autoFocus
          isLoading={isSubmitting}
          wiringVariant="sitePrimary"
          prefixIcon={submitIcon}
          onClick={onSubmit}
        >
          {submitText}
        </Button>
        <Button
          wiringVariant="siteSecondary"
          priority={ButtonPriority.basicSecondary}
          onClick={createCloseHandler(BiModalCloseTrigger.ClickCancelButton)}
        >
          {cancelText}
        </Button>
      </div>
    </Dialog>
  );
};
