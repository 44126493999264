import React, { type FC, useEffect, useCallback } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  ToastSkin,
  ToastPlacement,
  Toast as TPAToast,
} from 'wix-ui-tpa/cssVars';
import { useBiLogger } from '@services/BiLogger';
import { DataFocusId, useFocusManager } from '@services/FocusManager';
import { useToastContext } from './ToastContext';
import { st, classes } from './Toast.st.css';

export const Toast: FC = () => {
  const { currentToast, closeToast } = useToastContext();
  const { isMobile } = useEnvironment();
  const { biLogger } = useBiLogger();
  const { focusById } = useFocusManager();

  useEffect(() => {
    if (currentToast) {
      biLogger?.reportToastShown();
    }
  }, [biLogger, currentToast]);

  const handleClose = useCallback(() => {
    closeToast();
    focusById(DataFocusId.WidgetContent);
  }, [closeToast, focusById]);

  if (!currentToast) {
    return null;
  }

  return (
    <TPAToast
      shouldShowCloseButton
      // @ts-expect-error TODO
      skin={currentToast?.meta?.skin || ToastSkin.success}
      placement={
        isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline
      }
      className={st(classes.root, { isMobile })}
      onClose={handleClose}
    >
      {currentToast.text}
    </TPAToast>
  );
};
