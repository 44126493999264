import React, { type FC, useCallback, useEffect } from 'react';
import { useTranslation, useFedopsLogger } from '@wix/yoshi-flow-editor';
import { Plus } from '@wix/wix-ui-icons-common/on-stage';
import { Modal, Interaction } from '@constants';
import {
  useBiLogger,
  BiModalType,
  type BiAddButtonPlace,
} from '@services/BiLogger';
import { Button } from '@components/Button';
import { useModalContext } from '@components/Modal';

type Props = {
  renderPlace: BiAddButtonPlace;
};

export const AddButton: FC<Props> = ({ renderPlace }) => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();
  const { biLogger } = useBiLogger();
  const fedops = useFedopsLogger();

  useEffect(() => {
    biLogger?.reportAddButtonRendered({ renderPlace });
  }, [biLogger, renderPlace]);

  const handleClick = useCallback(() => {
    biLogger?.reportAddButtonClicked();

    openModal(Modal.AddPaymentMethod);

    fedops.interactionStarted(Interaction.LoadAddPaymentMethodModal);
    biLogger?.reportModalLoadStarted({
      modalType: BiModalType.AddPaymentMethod,
    });
  }, [biLogger, fedops, openModal]);

  return (
    <Button
      wiringVariant="primary"
      prefixIcon={<Plus aria-hidden="true" />}
      onClick={handleClick}
    >
      {t('app.add-payment-method-button.text')}
    </Button>
  );
};
