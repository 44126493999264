import React, { type FC, useState, useCallback } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { ButtonPriority } from 'wix-ui-tpa/cssVars';
import { useFocusManager, getManageMenuId } from '@services/FocusManager';
import { useBiLogger } from '@services/BiLogger';
import { Button } from '@components/Button';
import { ActionsMenu, type ActionsMenuProps } from './ActionsMenu';
import { MenuDrawer } from './MenuDrawer';
import { MenuPopover } from './MenuPopover';

export type ManageMenuProps = Pick<
  ActionsMenuProps,
  'id' | 'title' | 'items'
> & {
  showTitle?: boolean;
  // Used for preview in the Editor
  isControlledOpen?: boolean;
  isDisabled?: boolean;
};

export const ManageMenu: FC<ManageMenuProps> = (props) => {
  const {
    id,
    title,
    items,
    isDisabled,
    showTitle,
    isControlledOpen = false,
  } = props;
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const MenuBox = isMobile ? MenuDrawer : MenuPopover;
  const { biLogger } = useBiLogger();
  const { focusById } = useFocusManager();
  const menuFocusId = getManageMenuId(id);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleEsc = useCallback(() => {
    handleClose();
    focusById(menuFocusId);
  }, [focusById, handleClose, menuFocusId]);

  const handleToggle = useCallback(() => {
    setIsOpen((currentIsOpen) => {
      const nextIsOpen = !currentIsOpen;

      if (nextIsOpen) {
        biLogger?.reportManageButtonClicked({ savedPaymentMethodId: id });
      }

      return nextIsOpen;
    });
  }, [biLogger, id]);

  return (
    <MenuBox
      trigger={
        <Button
          disabled={isDisabled}
          wiringVariant="secondary"
          priority={ButtonPriority.secondary}
          aria-expanded={isOpen}
          aria-label={t('app.payment-method.manage-button.aria-label', {
            paymentMethodTitle: title,
          })}
          data-focus-id={menuFocusId}
          onClick={handleToggle}
        >
          {t('app.payment-method.manage-button.text')}
        </Button>
      }
      isOpen={isControlledOpen || isOpen}
      isRTL={isRTL}
      onClose={handleClose}
      onEscPress={handleEsc}
    >
      <ActionsMenu
        id={id}
        title={showTitle ? title : ''}
        items={items}
        onClose={handleClose}
      />
    </MenuBox>
  );
};
