import React, { type FC, type ReactNode } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Text } from '@components/Text';
import { st, classes } from './Feedback.st.css';

type FeedbackProps = {
  title: string;
  subtitle: string;
  actions?: ReactNode;
  className?: string;
};

export const Feedback: FC<FeedbackProps> = (props) => {
  const { title, subtitle, actions, className } = props;
  const { isMobile } = useEnvironment();

  return (
    <div
      className={st(classes.root, { isMobile }, classes.isCentered, className)}
    >
      <div className={st(classes.content)}>
        <Text tagName="h3" className={st(classes.title)}>
          {title}
        </Text>
        <Text tagName="p" className={st(classes.subtitle)}>
          {subtitle}
        </Text>
      </div>
      {actions && <div className={st(classes.actions)}>{actions}</div>}
    </div>
  );
};
