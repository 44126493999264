import React, { type FC, useMemo } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import type { SavedPaymentMethodWithMeta } from '@appTypes';
import type { AppError } from '@constants';
import { Modal } from '@constants';
import { getPaymentMethodTitle } from '@utils/paymentMethod';
import { getValueOrDefault } from '@utils/settings';
import { isEmpty } from '@utils/isEmpty';
import { BiAddButtonPlace } from '@services/BiLogger';
import { Layout } from '@components/Layout';
import { Feedback } from '@components/Feedback';
import type { PaymentMethodHandlers } from '@components/PaymentMethod';
import { PaymentMethodsList } from '@components/PaymentMethodsList';
import { AddButton } from '@components/AddButton';
import { useModalContext } from '@components/Modal';
import { AddPaymentMethodModal } from '@components/AddModal';
import { RemovePaymentMethodModal } from '@components/RemoveModal';
import { Toast } from '@components/Toast';
import settingsParams from '../../settingsParams';
import { ApplicationError } from './AppError';
import { st, classes } from './App.st.css';

export type AppProps = {
  isLoading?: boolean;
  appError?: AppError;
  managedPaymentMethodId?: string;
  deletedIDsQueue?: string[];
  paymentMethods?: SavedPaymentMethodWithMeta[];
  /**
   * A host property matching the payments widget server's initialization port to route internal requests locally.
   *
   * @remarks
   * See {@link https://github.com/wix-private/cashier-client/blob/master/packages/cashier-payments-widget/docs/TESTING.md#local-server}
   */
  paymentsWidgetHost?: string;
  siteCurrency: string;
  canAddNewPaymentMethod?: boolean;
  onAddNewSubmit: () => void;
  onRemovalSubmit: (paymentMethodId: string) => void;
  onDataFetchRetry: () => void;
} & PaymentMethodHandlers;

export const App: FC<AppProps> = (props) => {
  const {
    isLoading,
    appError,
    managedPaymentMethodId,
    deletedIDsQueue,
    paymentMethods = [],
    paymentsWidgetHost,
    siteCurrency,
    canAddNewPaymentMethod,
    onAddNewSubmit,
    onRemove,
    onRemovalSubmit,
    onMarkPrimary,
    onDataFetchRetry,
  } = props;
  const { t } = useTranslation();
  const { currentModal, closeModal } = useModalContext();
  const settings = useSettings();
  const isListEmpty = isEmpty(paymentMethods);
  const managedMethod = useMemo(() => {
    if (managedPaymentMethodId) {
      return paymentMethods.find(
        (method) => method.id === managedPaymentMethodId,
      );
    }
  }, [managedPaymentMethodId, paymentMethods]);

  return (
    <Layout
      isLoading={isListEmpty && isLoading}
      title={getValueOrDefault(settings, settingsParams.appTitle)}
      subtitle={getValueOrDefault(settings, settingsParams.appSubtitle)}
      className={st(classes.root)}
    >
      <ApplicationError error={appError} onRetry={onDataFetchRetry}>
        {isListEmpty ? (
          <Feedback
            title={getValueOrDefault(settings, settingsParams.noItemsTitle)}
            subtitle={getValueOrDefault(
              settings,
              settingsParams.noItemsSubtitle,
            )}
            actions={
              canAddNewPaymentMethod && (
                <AddButton renderPlace={BiAddButtonPlace.EmptyState} />
              )
            }
          />
        ) : (
          <PaymentMethodsList
            items={paymentMethods}
            deletedIDsQueue={deletedIDsQueue}
            actions={
              canAddNewPaymentMethod && (
                <AddButton renderPlace={BiAddButtonPlace.List} />
              )
            }
            onRemove={onRemove}
            onMarkPrimary={onMarkPrimary}
          />
        )}
        {currentModal === Modal.AddPaymentMethod && (
          <AddPaymentMethodModal
            currency={siteCurrency}
            paymentsWidgetHost={paymentsWidgetHost}
            onSubmit={onAddNewSubmit}
            onClose={closeModal}
          />
        )}
        {currentModal === Modal.ConfirmPaymentMethodRemoval &&
          managedMethod && (
            <RemovePaymentMethodModal
              isSubmitting={
                managedMethod.id
                  ? deletedIDsQueue?.includes(managedMethod.id)
                  : false
              }
              paymentMethodTitle={getPaymentMethodTitle(managedMethod, t)}
              paymentMethodId={managedMethod.id}
              onSubmit={onRemovalSubmit}
              onClose={closeModal}
            />
          )}
      </ApplicationError>
      <Toast />
    </Layout>
  );
};
