import React, { type FC } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { Badge, BadgePriority, Card, TextPriority } from 'wix-ui-tpa/cssVars';
import { Text } from '@components/Text';
import { ManageMenu } from '@components/ManageMenu';
import { ExpiryDate } from './ExpiryDate';
import { useManageItems } from './useManageItems';
import { PaymentMethodIcon } from './PaymentMethodIcon';
import type { PaymentMethodProps } from './PaymentMethod.types';
import { st, classes } from './PaymentMethod.st.css';

export const PaymentMethod: FC<PaymentMethodProps> = (props) => {
  const {
    id,
    isPrimary,
    isManageOpened,
    isManageDisabled,
    title,
    holder,
    expirationDate,
    image,
  } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const manageItems = useManageItems(props);

  return (
    <Card className={st(classes.root)}>
      <div className={st(classes.image)}>
        <PaymentMethodIcon url={image.url} />
      </div>
      <Card.Container className={st(classes.content)}>
        <div className={st(classes.details)}>
          <Text wiring tagName="h4" className={st(classes.title)}>
            {title}
            {isPrimary && (
              <Badge
                priority={BadgePriority.light}
                className={st(classes.primaryBadge)}
              >
                {t('app.payment-method.primary-mark.label')}
              </Badge>
            )}
          </Text>
          {(expirationDate || holder) && (
            <div className={st(classes.methodInfo)}>
              {expirationDate && <ExpiryDate value={expirationDate} />}
              {holder && (
                <Text wiring tagName="p" priority={TextPriority.secondary}>
                  {holder}
                </Text>
              )}
            </div>
          )}
        </div>
        <ManageMenu
          id={id}
          title={title}
          showTitle={isMobile}
          isControlledOpen={isManageOpened}
          isDisabled={isManageDisabled}
          items={manageItems}
        />
      </Card.Container>
    </Card>
  );
};
